import axios from "axios";
import React, { useEffect, useState } from "react";

interface UsersTableRowProps{
    accountID: string;
    name: string;
    email: string;
    phonenumber: string;
    trdingCurrency: string;
    gender: string;
    country: string;
    tier: string;
    kyc: boolean;
    totalBalance: number;
}

const UsersTableRow:React.FC<UsersTableRowProps> = ({accountID,name,email,phonenumber,trdingCurrency,gender,country,tier,kyc,totalBalance}) => {
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-50 dark:hover:bg-gray-600">
      <th
        scope="row"
        id="name"
        className="px-4 py-4 text-gray-900 whitespace-nowrap dark:text-white"
      >
        <p className="text-sm font-medium">{accountID}</p>
      </th>
      <td className="px-1 py-4 capitalize">
        <p className=" w-max px-2 py-[2px] rounded-sm text-sm font-semibold">
          {name}
        </p>
      </td>
      <td className="px-6 py-4 capitalize">{email}</td>
      <td className="px-4 py-4 text-slate-950">{phonenumber}</td>
      <td className="px-5 py-4 text-slate-950">{gender}</td>
      <td className="px-6 py-4 font-medium">{trdingCurrency}</td>
      <td className="px-6 py-4 capitalize">{tier}</td>
      <td className="px-4 py-4 text-slate-950">{country}</td>
      <td className="px-5 py-4 text-slate-950">{kyc ? "Yes" : "No"}</td>
      <td className="px-5 py-4 font-medium">{totalBalance}</td>
    </tr>
  );
};

interface UserDataInterface {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  trdingCurrency: string;
  gender: string;
  country: string;
  tier: string;
  kyc: boolean;
  totalBalance: number;
}

function UsersTable() {
  const [userData, setUserData] = useState<UserDataInterface[]>([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/user`);
      setUserData(response.data.data);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="relative w-full overflow-x-auto shadow sm:rounded-lg border border-slate-200 px-4 py-4 scrollbar-hide">
      <div className="pb-4 bg-white dark:bg-gray-900 flex flex-row items-center justify-between">
        <div className="relative mt-1">
          <p className="mb-3 text-xl font-medium">User info</p>
        </div>
      </div>
      <table className="w-full relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-4 py-3">
              Account ID
            </th>
            <th scope="col" className="px-4 py-3">
              Name
            </th>
            <th scope="col" className="px-4 py-3">
              email
            </th>
            <th scope="col" className="px-4 py-3">
              phonenumber
            </th>
            <th scope="col" className="px-4 py-3">
              Gender
            </th>
            <th scope="col" className="px-4 py-3">
              Trading <br /> Currency
            </th>
            <th scope="col" className="px-4 py-3">
              User Tier
            </th>
            <th scope="col" className="px-4 py-3">
              Country
            </th>
            <th scope="col" className="px-4 py-3">
              KYC
            </th>
            <th scope="col" className="px-4 py-3">
              Account <br /> Balance
            </th>
          </tr>
        </thead>
        <tbody>
            {
                userData.map((item,index)=>{
                    return (
                        <UsersTableRow
                        key={index}
                        accountID={item._id}
                        name={item.firstname + " " + item.lastname}
                        email={item.email}
                        phonenumber={item.phonenumber}
                        tier={item.tier}
                        totalBalance={item.totalBalance}
                        trdingCurrency={item.trdingCurrency}
                        country={item.country}
                        kyc={item.kyc}
                        gender={item.gender}
                        />
                    )
                })
            }

        </tbody>
      </table>
    </div>
  );
}

export default UsersTable;
