import React from 'react'
import UsersTable from './UsersTable'

function UserIndex() {
  return (
    <div className='relative w-full h-full flex flex-col items-center px-8 py-8'>
      <UsersTable />
    </div>
  )
}

export default UserIndex
