import React from 'react'
import PaymentsTable from './PaymentsTable'

function PaymentsIndex() {
  return (
    <div className='relative w-full h-full flex flex-col items-center px-16 py-8'>
        <PaymentsTable />
    </div>
  )
}

export default PaymentsIndex