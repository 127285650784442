import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    function handleUserChange(event: any) {
      setError("");
      setUsername(event.target.value);
    }
  
    function handlePasswordChange(event: any) {
      setError("");
      setPassword(event.target.value);
    }
  
    const AxiosLogin = async () => {
      try {
        const response = await axios.post("/api/v1/admin/login",{
          username: username,
          password: password,
        });
        console.log(response.data.data);
        sessionStorage.setItem("crypto-OauthToken", response?.data.authToken);
        sessionStorage.setItem("cryto-loggedIn", "true");
        navigate("/");
      } catch (error: any) {
        console.log(error.response.data);
        if (error.response.data.status === "-1") {
          setError("Invalid Email");
        } else if (error.response.data.status === "0") {
          setError("Invalid Password");
        }
      }
    };
    function handleSubmit(event: any) {
      event.preventDefault();
  
      // Validation logic
      if (!username.trim() || !password.trim()) {
        setError("Input cannot be empty");
        return;
      }
      // Reset error on successful validation
      setError("");
      AxiosLogin();
    }
  return (
    <div className="w-full h-[100vh] flex flex-col items-center justify-center bg-[#f8f8ff] transition-w duration-300 ease-in-out">
      <div className="w-[80%]  sm:w-[60%] md:w-[42%] lg:w-[30%] p-16 border-2 transition-w duration-300 ease-in-out flex flex-col items-center bg-white rounded-xl shadow-lg border-slate-200">
        <p className="text-lg font-bold text-slate-950 mb-5 uppercase">
          Admin Panel
        </p>
        <div className="mb-5 w-full">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Username
          </label>
          <input
            type="text"
            id="username"
            className="bg-slate-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Username"
            required
            value={username}
            onChange={handleUserChange}
          />
        </div>
        <div className="mb-5 w-full">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            className="bg-gray-50 border caret-blue-500 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
            onChange={handlePasswordChange}
          />
        </div>
        {error && <p className="text-red-600 font-medium mb-3">{error}</p>}
        <button
          type="submit"
          onClick={handleSubmit}
          className="text-white bg-slate-950 hover:bg-slate-800 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full mt-5 px-5 py-2.5 text-center"
        >
          Log in
        </button>
      </div>
    </div>
  )
}

export default LoginPage