import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import AdminPanel from "./pages/AdminPanel";
import PaymentsIndex from "./components/payments/PaymentsIndex";
import axios from "axios";
import LoginPage from "./pages/LoginPage";
import UserIndex from "./components/Users/UserIndex";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

axios.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("crypto-OauthToken");
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const isAuthenticated = () => {
  return sessionStorage.getItem("cryto-loggedIn") === "true";
};

function App() {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();

  useEffect(() => {
    if (!authenticated) {
        navigate("/login");
    }
  }, [authenticated, navigate]);
  return (
    <div className="w-[100vw] h-[100vh]">
      <Routes>
          <Route path="/" element={<AdminPanel />} >
            <Route index element={<PaymentsIndex />} />
            <Route path="/users" element={<UserIndex />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
